import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Translatable } from '@app/types/translatable.type';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'ui-info',
    templateUrl: './info.template.html',
    styleUrls: ['./info.style.scss'],
})
export class InfoComponent implements OnDestroy {
    @Input() tooltipPosition = 'after';
    @Input() text!: Translatable;
    @Input() matTooltipClass?: string;
    @ViewChild('tooltip') set matTooltip(tooltip: MatTooltip) {
        if (tooltip) {
            // We debounce the touch events so that accidental multi-touches won't dismiss the tooltip
            this.touch$.pipe(debounceTime(250)).subscribe((target) => {
                // If they press within the tooltip it should be displayed
                if (this.elementRef.nativeElement.contains(target)) {
                    tooltip.toggle();
                } else {
                    // If they touch outside of the tooltip (ie. to scroll) it should be dismissed
                    tooltip.hide();
                }
            });
        }
    }

    touch$ = new BehaviorSubject<EventTarget | null>(null);

    constructor(private elementRef: ElementRef) {}

    @HostListener('document:touchstart', ['$event'])
    touch(event: Event): void {
        this.touch$.next(event.target);
    }

    ngOnDestroy(): void {
        this.touch$.complete();
        this.touch$.unsubscribe();
    }
}
