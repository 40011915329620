/**
 * Add LaunchDarkly feature flags here
 * The key can be anything. The value must match the LaunchDarkly value.
 * ie: myNewFeatureFlag = 'my-new-feature-flag'
 *
 * A similar but not identical enum can be found under: apps/hr-e2e/src/support/enums/feature-flag.ts
 *
 * TIP: Are you a developer from the future looking to learn from past mistakes? Terrific! If you're using a good
 * IDE you'll notice "unused readonly field" warnings with suggestions to remove them. Alas, this file is used to
 * build mock Launch Darkly interceptors when running CI. Only delete when absolutely certain.
 *
 * @see getLocalFeatureFlags
 */
export enum FeatureFlag {
    /**
     * Used in a test of feature flags
     */
    sampleFlag = 'sample-flag',
    payrollMaintenance = 'payroll-maintenance',
    benefitsUpsell = 'benefits-upsell',
    companyAnnouncements = 'company-announcements',
    onboardingMultiSignDocuments = 'onboarding-multi-sign-documents',
    offerLetters = 'offer-letters',
    customFieldsForOnboarding = 'custom-fields-for-onboarding',
    customFieldImprovements = 'custom-field-improvements',
    errorPage = 'error-page',
    zapierIntegration = 'zapier-integration',
    asyncSystemTasks = 'async-system-tasks',
    vacationYTDBalancesReport = 'vacation-ytd-balances-report',
    customFieldsCreationImprovements = 'custom-fields-creation-improvements',
    customFieldsOnDocuments = 'custom-fields-on-documents',
    automatedEmailSettings = 'automated-email-settings',
    offerLetterPreviews = 'offer-letter-previews',
    peopleReport2021 = 'people-report-2021',
    onboardingVideo = 'onboarding-video',
    enableT4ForTaxYear = 'enable-t4-for-tax-year',
    enableAdjusterForCurrentYear = 'enable-adjuster-for-current-year',
    courierRecruitingNotifications = 'courier-recruiting-notifications',
    taxAdjuster = 'tax-adjuster',
    changeRequests = 'change-requests',
    ameegoHumiImport = 'ameego-humi-import',
    employeeNamePronunciation = 'employee-name-pronunciation',
    employeeVacationPayMethod = 'employee-vacation-pay-method',
    translationDebugToggle = 'translation-debug-toggle',
    jobBoardV2MobileViews = 'job-board-v2-mobile-views',
    performanceModuleSplit = 'performance-module-split',
    orgChartV2 = 'org-chart-v2',
    timeWorkedImportPolling = 'time-worked-import-polling',
    upsell = 'upsell',
    upsellV2 = 'upsell-v2',
    bonusTaxWarning = 'bonus-tax-warning',
    standardErrorResponse = 'standard-error-response',
    productBoardFeatureRequestForm = 'product-board-feature-request-form',
    simplyBenefits = 'simply-benefits',
    selfServeModules = 'self-serve-modules',
    performanceReviewImprovements = 'performance-review-improvements',
    employeeImportIntegrations = 'employee-import-integrations',
    timeOffPayrollIntegrationV2 = 'time-off-payroll-integration-v2',
    qcPayroll = 'qc-payroll',
    auth0Optional = 'auth0-optional',
    auth0Redirect = 'auth0-redirect',
    MFAAccountEnabled = 'mfa-account-enabled',
    yearEnd2023 = 'year-end-2023',
    taxCreditUIImprovements = 'tax-credit-ui-improvements',
    timeOffImprovements = 'time-off-improvements',
    atsApplicantConsent = 'ats-applicant-consent',
    timeOffSelfServe = 'time-off-self-serve',
    improvedHomepage = 'improved-homepage',
    birthdayVisibility = 'birthday-visibility',
    ytdImporterAgGrid = 'ytd-importer-ag-grid',
    ytdTableMultiCellPaste = 'ytd-table-multi-cell-paste',
    KYC_KYB = 'kyc-kyb',
    KYC_KYBRevalidation = 'kyc-kyb-revalidate',
    documentsV2 = 'documents-v2',
    globalDocumentFolders = 'global-document-folders', // Currently unused in the UI; included to ensure the option appears in the Humi Feature Flag Chrome plugin.
    linkedinXmlIntegration = 'linkedin-xml-integration',
    timeClock = 'time-clock',

    /**
     * Temporary flag for testing new job board integrations card. After this has been live for a short while, we
     * should be okay to remove it, along with all the old job-board-integrations card related code.
     */
    jobBoardIntegrationsCardV2 = 'job-board-integrations-card-v2',
    editPayrollAgGrid = 'edit-payroll-ag-grid',

    benefitsPayroll = 'benefits-payroll',
    humiRebrand2024 = 'humi-rebrand-2024',
    improvedTimeOffReports = 'improved-time-off-reports',
    compensationReport = 'compensation-report',
    improveTimeOffRequestExperience = 'improve-time-off-request-experience',
    detailAdditionsAndTerminationsReport = 'detail-additions-and-terminations-report',
    timeClockTimer = 'time-clock-timer',
    darkMode = 'dark-mode',
    bulkUpdateBox45 = 'payroll-bulk-update-box-45',
    improvedBenefitsCoverage = 'improved-benefits-coverage',
    kybResendUBOInvites = 'kyb-resend-ubo-invites',
    emailTaxFormsConsent = 'email-tax-forms-consent',
    taxAdjusterAddForm = 'tax-adjuster-add-form',
    flinksConnect = 'flinks-connect',
    disableNewLinkedInConnections = 'disable-new-linked-in-connections',
    disableIndeedIntegration = 'disable-indeed-integration',
    payrollContact = 'payroll-contact',
}
/**
 * The actual value of the flag. Allows for type-safe use of the flag enum values without having to import the enum
 */
export type FeatureFlagKey = `${FeatureFlag}`;
